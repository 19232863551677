<template>
    <div 
        class='col-md-8 offset-md-2'
       
    >
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class='mt-md-10'>
            <v-form ref='form_request_advance' v-if="access">
                <v-col>
                    <v-card tile class='text-center' color='blue-grey lighten-1 white--text py-2 mx-4'>
                        Solicitud de anticipo a proveedor
                    </v-card>
                    <v-row >
                        <v-col cols=12>
                            <v-list-item dense>
                                <v-list-item-icon class='mr-2'>
                                    <v-icon>mdi-account-star</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title><b>PROVEEDOR </b></v-list-item-title>
                                <v-list-item-subtitle> {{advanceQuote.name}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-icon class='mr-2'>
                                    <v-icon >mdi-package-up</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title><b>COTIZACIÓN # </b> </v-list-item-title>
                                <v-list-item-subtitle> {{advanceQuote.quote_id}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>TOTAL : </b> ${{advanceQuote.total}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>RETENCIÓN IVA : </b> ${{advanceQuote.retiva}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>     
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>RETENCIÓN RENTA : </b> ${{advanceQuote.retrenta}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>     
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>A PAGAR : </b> ${{advanceQuote.a_pagar}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>          
                            <v-list-item dense>
                                <v-list-item-icon class='mr-2'>
                                    <v-icon>mdi-cash-usd</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title><b>MONTO DEL ANTICIPO </b></v-list-item-title>
                                <v-list-item-subtitle>${{advanceQuote.monto}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>    
                            <v-list-item dense>
                                <v-list-item-icon class='mr-2'>
                                    <v-icon>mdi-cash-100</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title><b>FORMA DE PAGO </b></v-list-item-title>
                                <v-list-item-subtitle> {{advanceQuote.payment_method}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>    
                            <v-list-item dense>
                                <v-list-item-icon class='mr-2'>
                                    <v-icon>mdi-bank</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title><b>CUENTA DE ORIGEN DEL DÉBITO: </b></v-list-item-title>
                                <v-list-item-subtitle> {{advanceQuote.fin_account_name}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>         
                            <v-list-item dense>
                                <v-list-item-icon class='mr-2'>
                                    <v-icon>mdi-comment-multiple-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title><b>DESCRIPCIÓN: </b></v-list-item-title>
                                <v-list-item-subtitle> {{advanceQuote.description}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>   
                            <v-list-item dense>
                                <v-list-item-icon class='mr-2'>
                                    <v-icon>mdi-link</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-btn 
                                            link 
                                            :href="`${urlExternal}/compras/control/verCotizacion?quoteId=${advanceQuote.quote_id}`" 
                                            target='_blank'
                                            small color='primary'> Haga click aquí para ir a la cotización
                                        </v-btn>    
                                    </v-list-item-title>                        
                                </v-list-item-content>
                            </v-list-item>          
                        </v-col>
                    </v-row>
                    <v-row v-if="advanceQuote.quote_status=='CAL_TAR_PLANIFICADO'">
                        <v-col class="text-center">
                            <v-btn
                                color="primary"
                                class='mr-md-2'
                                @click="aproveAdvance"
                            >
                                <v-icon>mdi-check-all</v-icon> APROBAR
                            </v-btn>
                            <v-btn 
                                color='red'
                                class='white--text ml-md-2'
                                @click="rejectAdvance"
                            > 
                                <v-icon>mdi-cancel</v-icon> RECHANZAR
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-col v-else>
                        <v-alert
                            dense
                            color="primary"
                            class="text-uppercase text-center"
                            dark
                        >
                            {{advanceQuote.stauts_desription}}
                        </v-alert>
                    </v-col>
                </v-col>
            </v-form>
            <v-col v-else class='deep-orange darken-1 white--text'>
                <v-row align="center" >
                    <v-col>
                        <v-icon class='white--text'>mdi-sync-alert</v-icon> Usted no es el propietario de la tarea, por lo que o puede completarla
                    </v-col>
                    <v-col class="shrink">
                    <v-btn to='/' color='primary'>Volver a tareas</v-btn>
                    </v-col>
                </v-row>
            </v-col >
        </v-card>
    </div>
</template>


<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import Vue from 'vue'

export default {
    name : 'AproveAdvanceSupplierComponent',
    data: ()=> ({
        overlay:true,
        access:false,
        advanceQuote:{}
    }),
    computed:{
        
        ...mapState('master',['paramAlertQuestion','user','urlExternal'])

    },
    methods:{

        ...mapMutations('master',['setUrl']),

        ...mapActions('master',['requestApi','alertNotification']),

        verifyWorkEffort(){

            this.setUrl('lista-cotizacion-proveedor')
            this.requestApi({
                method: 'PATCH',
                data: { 
                    workEffortId: this.$route.params.workEffortId,
                    paymentGroupId: this.$route.params.paymentGroupId,
                    typeData: 'dataAdvanceSupplier'
                }
            }).then(res =>{
                
                this.access = res.data.detail[0].exists
                this.advanceQuote = res.data.detail[1]
                
            }).catch(()=>{
                
            }).then(()=>{
                this.overlay=false  
            })

        },

        aproveAdvance(){
            
            Vue.swal({
                html: "Está seguro de aprobar la solicitud?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {

                    this.setUrl('lista-cotizacion-proveedor')
                    this.requestApi({
                        method: 'POST',
                        data: {
                            supplier : this.advanceQuote.name,
                            workEffortId: this.$route.params.workEffortId,
                            paymentGroupId: this.$route.params.paymentGroupId,
                            typeStore: 'aproveAdvanceSupplier',
                            quoteId: this.advanceQuote.quote_id
                        }
                    }).then(res =>{

                        console.log(res)

                        this.alertNotification({param:{html: res.data.res.msg}})   
                        location.reload()
                      
                    })

                }

            })

        },

        rejectAdvance(){

            Vue.swal({
                html: "Está seguro de rechazar la solicitud de anticipo de este proveedor?",
                icon: "warning",
                input: 'textarea',
                inputPlaceholder: 'Escriba un comentario',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion,
                inputValidator: (value) => {
                    if (!value) 
                        return 'Debe escribir un comentario'
                }
            }).then( result => {
                if (result.isConfirmed) {
                    
                    this.setUrl('lista-cotizacion-proveedor')

                    this.requestApi({
                        method: 'POST',
                        data :{
                            workEffortId: this.$route.params.workEffortId,
                            paymentGroupId: this.$route.params.paymentGroupId,
                            quoteId: this.advanceQuote.quote_id,
                            description: result.value,
                            typeStore: 'rejectAdvanceSupplier'                            
                        }
                    }).then(res =>{

                        this.alertNotification({param:{html: res.data.res.msg}})
                        location.reload()
			        })

                }
            })

        }

    },
    created(){

        this.verifyWorkEffort()
    }
}
</script>